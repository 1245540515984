<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";
import { getPartyReportsList } from "@/services/api/party.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import PowerbiViewer from "@/components/party/PowerBIViewer.vue";

const { formatDateUtcD } = useDateTimeFormatter();

const props = defineProps({
  partyReference: String,
  reportId: String,
});

const workspaceId = ref("");
const componentKey = ref(0);
const selectedReportId = ref("");
const reports = ref([]);
const initialReportId = ref(undefined);

watch(
  () => props.partyReference,
  () => {
    loadReports(props.partyReference);
  },
  { immediate: true }
);

watch(
  () => props.reportId,
  () => {
    initialReportId.value = props.reportId;
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function selectReport(i) {
  const report = reports.value[i];

  if (report) {
    selectedReportId.value = i;
    workspaceId.value = report.workspaceId;
    initialReportId.value = report.reportId;
    //console.log("powerbiselector selectReport", workspaceId.value, initialReportId.value);
    componentKey.value++;
  } else {
    proxy.$toaster.error("Fout bij ophalen rapportage");
  }
}

async function loadReports(partyReference) {
  var response = await getPartyReportsList(partyReference);
  //console.log("powerbiselector loadReports", response, initialReportId.value);

  if (response && response.data != undefined && response.error == undefined) {
    reports.value = response.data;

    if (reports.value && reports.value.length > 0) {
      let reportToSelect = 0;
      if (initialReportId.value) {
        for (let i = 0; i < reports.value.length; i++) {
          if (reports.value[i].id == initialReportId.value) {
            reportToSelect = i;
            break;
          }
        }
      }
      initialReportId.value = undefined;
      selectReport(reportToSelect);
    } else {
      componentKey.value++;
    }
  }
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col lg="2" md="2" sm="12" class="pbi-report-sidebar pa-2" v-if="reports.length > 0">
        <h3 class="pa-3">Beschikbare rapportages</h3>
        <v-btn-toggle v-model="selectedReportId" @change="selectReport" class="pbi-report-sidebar">
          <v-list nav class="pbi-report-sidebar">
            <v-list-item text v-for="(itm, index) in reports" :key="index" :value="itm.id">
              <v-list-item-title>
                <v-btn color="white" class="form-control"
                  >{{ itm.name }} <br />
                  {{ formatDateUtcD(itm.reportDate) }}</v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-btn-toggle>
      </v-col>
      <v-col lg="10" md="10" sm="12" class="app-dashboard-overview">
        <powerbi-viewer :workspaceId="workspaceId" :reportId="reportId" :componentKey="componentKey" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.v-btn-toggle {
  flex-direction: column;
}
</style>
